export const monthList = [
  "janvier",
  "fevrier",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "aout",
  "septembre",
  "octobre",
  "novembre",
  "decembre",
];

export const newMonthlyUsers = (subscriptionsList) => {
  let actual = new Date().getMonth();
  let res = 0;
  if (subscriptionsList.length > 0) {
    let subscriptionsDate = null;
    for (let subscriptions of subscriptionsList) {
      subscriptionsDate = new Date(subscriptions.createdAt);
      if (subscriptionsDate.getMonth() === actual) {
        res++;
      }
    }
  }
  return res;
};

export const paymentStats = (subscriptionsList) => {
  const list = [0, 0, 0, 0, 0];
  
  //['Orange Money', 'MOMO', 'Yellow', 'Carte Bancaire', 'autre']
  for (let elt of subscriptionsList) {
    console.log(elt.payement)
    switch (elt.payement) {
      case "MOMO":
        list[1]++;
        break;
      case "OM":
        list[0]++;
        break;
      case "Yellow":
        list[2]++;
        break;
      case "Carte":
        list[3]++;
        break;
      case "autre":
        list[4]++;
        break;
      default:
        break;
    }
  }
  return list;
};


export const websiteTypeStats = (subscriptionsList) => {
  const list = [0, 0, 0, 0, 0];
  
  //['Site Vitrine', 'E-commerce',  'autre']
  for (let elt of subscriptionsList) {
    
    switch (elt.souhait) {
      case "ecommerce":
        list[1]++;
        break;
      case "vitrine":
        list[0]++;
        break;
      case "autre":
        list[2]++;
        break;
      default:
        break;
    }
  }
  return list;
};

