import React from 'react';
import {Helmet} from "react-helmet"

const SEO = ({title, description}) => {
  return (
    <Helmet 
        title={title}  
        htmlAttributes={{ lang: "fr" }}
        meta={[
            {
              name: `description`,
              content: description,
            },
            {
              name:`keywords`,
              content:"otoweb , bëtacar, betacardrive"
            }
          ]}
    />
  )
}

export default SEO