import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import {FaHome} from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router";
import { CircularProgress } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { AiFillWarning } from "react-icons/ai";
import { RegisterApi } from "../../utils/ApiRoute";
import Logo from "../../assets/logo1.png";

const Subscription = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    souhait: "",
    attestation: "",
    email: "",
    lien: "",
    nom: "",
    payement: "",
    registre: "",
    social: "",
    tel: "",
    siege: "",
    cni: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    console.log(values)

    if (
      values.souhait &&
      values.attestation &&
      values.email &&
      values.lien &&
      values.nom &&
      values.payement &&
      values.registre &&
      values.social &&
      values.tel &&
      values.siege &&
      values.cni
    ) {
      const { data } = await axios.post(RegisterApi, values)
      if (data.status === true) {
        toast.success(`enregistrement reussi`, {
          position: "top-center",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        setOpenModal(true);
       setIsLoading(false);
        console.log(data);
      } else {
        setIsLoading(false);
        toast.error(data.error, {
          position: "top-center",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        console.log(data.error);
      }
    } else {
      setIsLoading(false);
      toast.error("Veuillez renseigner le(s) champ(s) manquants", {
        position: "top-center",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  };
  const effacer = () => {
    setIsLoading(false);
    setValues({
      souhait: "",
      attestation: "",
      email: "",
      lien: "",
      nom: "",
      payement: "",
      registre: "",
      social: "",
      tel: "",
      siege: "",
      cni: "",
    });
  };
  return (
    <section>
      <div className="py-4">
        <h1 className="flex items-center text-center justify-center text-red-600 font-bold text-2xl">
          <span className="mr-1">
            <AiFillWarning />
          </span>
          Avertissement!{" "}
        </h1>
        <h2 className="my-2 text-slate-700 text-md md:text-xl text-center font-bold">
          Vous reconnaissez avoir pris connaissance des conditions de
          souscriptions au programme 1 entreprise 1 site web qui permet d’avoir
          un site internet pour votre entreprise et les services associés et
          vous êtes parmi les cents premières entreprises éligibles au
          programme. Raison pour laquelle vous remplissez le formulaire
        </h2>
      </div>
      <div className="my-2">
        <h2 className="text-primary uppercase text-xl font-bold text-center">
          Formulaire de Souscription Bëtacar
        </h2>
        <div className="formContent">
          <form className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 my-4  [&>*]:my-1 ">
            <div>
              <label className="text-slate-700 font-bold text-lg" htmlFor="Nom">
                Nom Et Prenom *
              </label>
              <br />
              <input
                type="text"
                className="peer w-full px-2 py-2 border border-slate-500 rounded-md text-md  shadow-sm placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary  "
                name="nom"
                value={values.nom}
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            <div>
              <label
                className="text-slate-700 font-bold text-lg"
                htmlFor="Email"
              >
                Email *
              </label>
              <br />
              <input
                type="email"
                name="email"
                className="peer w-full px-2 py-2 border border-slate-500 rounded-md text-md  shadow-sm placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary  "
                value={values.email}
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            <div>
              <label className="text-slate-700 font-bold text-lg" htmlFor="Tel">
                N° Téléphone *
              </label>
              <br />
              <input
                type="number"
                className="peer w-full px-2 py-2 border border-slate-500 rounded-md text-md  shadow-sm placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary  "
                name="tel"
                value={values.tel}
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            <div>
              <label className="text-slate-700 font-bold text-lg" htmlFor="Cni">
                Numero de cni *
              </label>
              <br />
              <input
                type="number"
                className="peer w-full px-2 py-2 border border-slate-500 rounded-md text-md  shadow-sm placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary  "
                name="cni"
                value={values.cni}
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            <div>
              <label
                className="text-slate-700 font-bold text-lg"
                htmlFor="Raison"
              >
                Raison Sociale *
              </label>
              <br />
              <input
                type="text"
                className="peer w-full px-2 py-2 border border-slate-500 rounded-md text-md  shadow-sm placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary  "
                name="social"
                value={values.social}
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            <div>
              <label
                className="text-slate-700 font-bold text-lg"
                htmlFor="Attestation"
              >
                Attestation D'imatriculation *
              </label>
              <br />
              <input
                type="text"
                className="peer w-full px-2 py-2 border border-slate-500 rounded-md text-md  shadow-sm placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary  "
                name="attestation"
                value={values.attestation}
                onChange={(e) => handleChange(e)}
                required
              />
            </div>

            <div>
              <label
                className="text-slate-700 font-bold text-lg"
                htmlFor="Registre"
              >
                Registre de Commerce *
              </label>
              <br />
              <input
                type="text"
                className="peer w-full px-2 py-2 border border-slate-500 rounded-md text-md  shadow-sm placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary  "
                name="registre"
                value={values.registre}
                onChange={(e) => handleChange(e)}
                required
              />
            </div>

            <div>
              <label
                className="text-slate-700 font-bold text-lg"
                htmlFor="siege"
              >
                Siège de l'entreprise *
              </label>
              <br />
              <input
                type="text"
                className="peer w-full px-2 py-2 border border-slate-500 rounded-md text-md  shadow-sm placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary  "
                name="siege"
                value={values.siege}
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            <div>
              <label
                className="text-slate-700 font-bold text-lg"
                htmlFor="lien"
              >
                Lien du site *
              </label>
              <br />
              <input
                type="text"
                className="peer w-full px-2 py-2 border border-slate-500 rounded-md text-md  shadow-sm placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary  "
                name="lien"
                value={values.lien}
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            <div>
              <label
                className="text-slate-700 font-bold text-lg"
                htmlFor="Payement"
              >
                Mode de Payement *
              </label>
              <br />
              <select
                name="payement"
                className="peer w-full px-2 py-2 border border-slate-500 rounded-md text-md  shadow-sm placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary  "
                value={values.payement}
                onChange={(e) => handleChange(e)}
                required
              >
                <option disabled value="">
                  Payement
                </option>
                <option value="OM">Orange Money</option>
                <option value="MOMO">MOMO</option>
                <option value="Carte">Carte Bancaire</option>
                <option value="autre">autre</option>
              </select>
            </div>
            <div>
              <label
                className="text-slate-700 font-bold text-lg"
                htmlFor="Souhait"
              >
                Que souhaitez-vous? *
              </label>
              <br />
              <select
                name="souhait"
                value={values.souhait}
                onChange={(e) => handleChange(e)}
                className="peer w-full px-2 py-2 border border-slate-500 rounded-md text-md  shadow-sm placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary  "
                required
              >
                <option disabled value="">
                  option
                </option>
                <option value="vitrine">Site Vitrine</option>
                <option value="ecommerce">E-commerce</option>
                <option value="autre">autre</option>
              </select>
            </div>
          </form>
          <div
            className={`flex justify-center ${
              !isLoading &&
              " [&>*]:mr-4 [&>*]:py-1 [&>*]:px-6 [&>*]:text-lg  [&>*]:rounded-sm  [&>*]:border  my-8 "
            }`}
          >
            <button
              className={`${
                isLoading
                  ? "hidden"
                  : "border-primary text-primary hover:bg-slate-100"
              }`}
              onClick={effacer}
            >
              Effacer
            </button>

            {isLoading ? (
              <CircularProgress size="35px" className=" my-2" color="inherit" />
            ) : (
              <button
                className="bg-primary text-white border-primary hover:opacity-[0.9]"
                onClick={(e) => handleSubmit(e)}
              >
                Souscription
              </button>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="bg-transparent h-full w-full flex justify-center items-center">
          <div className="bg-white p-4 min-h-[35vh] min-w-[40vw] shadow-xl rounded-lg m-4 ">
            <div className="flex justify-center items-center">
              <img
                src={Logo}
                className="grow-0 shrink-0  h-12 translate-x-[-2]"
                alt="Bëtacar"
              />
            </div>
            <div className="my-8 px-3 text-center ">
              <p className="text-xl text-green-600 font-bold">
                Nous vous remercions d'avoir souscrit au programme OTOWEB
              </p>
            </div>
            <div className="flex justify-center [&>*]:mr-4 [&>*]:py-1 [&>*]:px-6 [&>*]:text-md  [&>*]:rounded-sm  [&>*]:border ">
              <button
                className="bg-green-600 text-white border-green-600 hover:opacity-[0.9] flex items-center"
                onClick={() => {
                  navigate("/");
                }}
              >
                <span> <FaHome className="mr-1" /></span>Accueil
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </section>
  );
};

export default Subscription;
