import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import Law from "../../assets/law.png";
import Services from "../../assets/services.png";
import Signature from "../../assets/signature.png";
import Cookie from "../../assets/cookie.png";
import Door from "../../assets/door.png";
import Court from "../../assets/court.png";
import LinkUrl from "../../assets/link.png";
import Responsible from "../../assets/responsible.png";

const ArticlesList = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, once: true });
  }, []);

  return (
    <div className="md:py-14">
      {/**
       * <span className="font-bold text-slate-700">655 54 57 02
       *
       */}
      <p className="my-2  text-slate-800 text-md md:text-xl break-words ">
        Les présentes conditions générales d'utilisation (dites{" "}
        <span className="font-bold text-slate-700">« CGU »</span>) ont pour
        objet l'encadrement juridique des modalités de mise à disposition du
        site web et du programme OTOWEB par Bëtacar Drive Technologies et de
        définir les conditions d’accès et d’utilisation du programme 100
        entreprises, 100 sites web par « l'Utilisateur ». Les présentes CGU sont
        accessibles sur le site à la rubrique (CGU) et le visiteur ne peut
        prétendre souscrire au PROGRAMME OTOWEB (100 ENTREPRISES, 100 SITES WEB)
        sans les avoir lu et accepté, en version physique et se doivent d’être
        lues et signées avant tout début de collaboration.
      </p>

      <div className=" mt-8">
        {/**article  1 */}
        <div
          data-aos="fade-up"
          className="aos flex flex-col md:flex-row items-center py-2 md:pb-8"
        >
          <img
            src={Law}
            alt="law illustration"
            className="md:basis-1/3 object-contain h-[15vh] md:h-[40vh] "
          />
          <div className="md:basis-2/3 text-center md:text-start mt-3 md:m-none">
            <h2 className="text-2xl text-primary font-bold text-center pb-2">
              ARTICLE 1 : LES MENTIONS LEGALES
            </h2>
            <p className="text-sm md:text-xl text-slate-800">
              L'édition du site{" "}
              <Link
                to="https://www.otoweb.betacardrive.com"
                className="font-bold text-slate-700"
                target="_blank"
              >
                otoweb.betacardrive.com
              </Link>{" "}
              est assurée par Bëtacar Drive Technologies et la Société Global
              Créa au capital de neuf cent mille (900.000) XAF CFA, immatriculée
              au NIU: P049314878534A et au RCCM : RC/YAO/2020/A2730, avec pour
              siège social CARREFOUR IPTEK. Le Numéro de téléphone disponible
              dans le cadre de ce programme est le{" "}
              <a href="tel:+237699234447">+237 699 234 447</a> Adresse e-mail :{" "}
              <a href="email:otoweb@betacardrive.com">
                otoweb@betacardrive.com
              </a>
              . Les Directeurs de la publication sont : Frankee SEME et AWONO
              Eric Ghislain. L'hébergeur des sites{" "}
              <Link
                to="https://www.otoweb.betacardrive.com"
                className="font-bold text-slate-700"
                target="_blank"
              >
                otoweb.betacardrive.com
              </Link>{" "}
              et du site betacardrive.com est la société{" "}
              <a href="https://crinteck.com">CRINTECK</a> dont le siège social
              est situé à Bamako , avec le numéro de téléphone :{" "}
              <a href="tel:+22372611214">+223 72 61 12 14</a>
            </p>
          </div>
        </div>

        {/** article  2 */}
        <div
          data-aos="fade-up"
          className="aos flex flex-col md:flex-row-reverse items-center py-4 md:py-8"
        >
          <img
            src={Door}
            alt="service illustration"
            className="md:basis-1/3 object-contain h-[15vh] md:h-[40vh] md:translate-y-6 "
          />
          <div className="md:basis-2/3 text-center md:text-start mt-3 md:mt-[10vh]">
            <h2 className="text-2xl text-primary font-bold text-center pb-2">
              ARTICLE 2 : ACCES ET SORTIE DU PROGRAMME
            </h2>

            <div className="text-sm md:text-xl text-slate-800">
              <p>
                Le programme OTOWEB (100 entreprises 100 sites web) permet aux
                entreprises de bénéficier des services suivants et seuls les
                services inclus dans le programme peuvent bénéficier du prix
                exceptionnel prévu à cet effet:
              </p>
              <div className=" text-slate-700 md:ml-4">
                <p>
                  -|Marketing opérationnel et webmarketing (non inclus dans le
                  programme)
                </p>
                <p>-|Infographie et services d’imprimerie</p>
                <p>
                  -|Conception des sites web (disponible au tarif exceptionnel
                  uniquement pendant la durée du programme)
                </p>
              </div>
            </div>
          </div>
        </div>

        {/**article  3 */}
        <div
          data-aos="fade-up"
          className="aos flex flex-col md:flex-row items-center py-2 md:py-8"
        >
          <img
            src={Signature}
            alt="signature illustration"
            className="md:basis-1/3 object-contain h-[25vh] md:h-[70vh] "
          />
          <div className="md:basis-2/3 text-center md:text-start md:mt-3 md:m-none">
            <h2 className="text-2xl text-primary font-bold text-center pb-2">
              ARTICLE 3 : SOUSCRIPTION ET SIGNATURE
            </h2>
            <div className="text-sm md:text-xl text-slate-800 ">
              <p className="mb-2">
                {" "}
                Lorsque l’utilisateur manifeste l’intérêt de souscrire au
                programme, il doit :
              </p>
              <div className="md:ml-4">
                <p>
                  - Lire et signer (s’il préfère la documentation papier) ou
                  accepter ces conditions générales d’abord directement sur le
                  site.
                </p>
                <p>
                  - Remplir un formulaire en renseignant toutes les informations
                  correctes nécessaires.
                </p>
                <p>
                  - Il doit répondre au téléphone pour la confirmation ou
                  modification des informations renseignées et connaitre la
                  suite de la procédure{" "}
                </p>
                <p>- Passer la consultation téléphonique</p>
                <p>
                  - Payer les frais de conception du cahier de charge qui
                  s’élèvent à 15 000 FCFA et payable une seule fois.
                </p>
                <p>
                  {" "}
                  - Mettre en place un plan de paiement automatique ou manuel
                  qui prend effet trente (30) jours après livraison des travaux.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/** article  4 */}
        <div
          data-aos="fade-up"
          className="aos flex flex-col md:flex-row-reverse items-center py-2 md:pb-8 md:pt-2"
        >
          <img
            src={Services}
            alt="service illustration"
            className="md:basis-1/3 object-contain h-[15vh] md:h-[60vh] md:translate-y-8 "
          />
          <div className="md:basis-2/3 text-center md:text-start mt-3 md:mt-[10vh]">
            <h2 className="text-2xl text-primary font-bold text-center pb-2">
              ARTICLE 4 : PROCEDURE DE TRAVAIL
            </h2>

            <div className="text-sm md:text-xl text-slate-800 ">
              <p className="mb-2">
                Après signature ou acceptation des présentes conditions, le
                client :
              </p>
              <div className="md:ml-4">
                <p>
                  - Effectue le paiement de son cahier de charge (il reçoit la
                  confirmation de paiement et date de la livraison du cahier de
                  charge)
                </p>
                <p>
                  - Reçoit son cahier de charge pour vérification et
                  confirmation que tout est correct et que la conception de sa
                  plateforme peut commencer (possibilité de modifier le cahier
                  de charge 2 fois).
                </p>
                <p>
                  {" "}
                  - Reçoit un lien pour suivre le processus de développement de
                  son site web pour demander des modifications si nécessaire.{" "}
                </p>
                <p></p>
              </div>
              <p className="my-2"> A la fin des travaux, le client :</p>
              <div className="md:ml-4">
                <p>- Reçoit une charte de livraison à signer</p>
                <p>
                  {" "}
                  - Les prélèvements peuvent donc commencer trente jours plus
                  tard
                </p>
                <p>
                  {" "}
                  - Le client peut nous solliciter pour la maintenance et/ou
                  d’autres services.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/** article  5 */}
        <div
          data-aos="fade-up"
          className="aos flex flex-col md:flex-row items-center py-2 md:pb-8 md:pt-2"
        >
          <img
            src={Responsible}
            alt="service illustration"
            className="md:basis-1/3 object-contain h-[15vh] md:h-[70vh] md:translate-y-6 "
          />
          <div className="md:basis-2/3 text-center md:text-start mt-3 md:mt-[10vh]">
            <h2 className="text-2xl text-primary font-bold text-center pb-2">
              ARTICLE 5 : RESPONSABILITE
            </h2>

            <p className="text-sm md:text-xl text-slate-800 ">
              Les sources des informations diffusées sur les sites{" "}
              <Link
                to="https://www.otoweb.betacardrive.com"
                className="font-bold text-slate-700"
                target="_blank"
              >
                otoweb.betacardrive.com
              </Link>{" "}
              et betacardrive.cm sont réputées fiables mais le site ne garantit
              pas qu’il soit exempt de défauts, d’erreurs ou d’omissions. Malgré
              des mises à jour régulières, le site{" "}
              <Link
                to="https://www.otoweb.betacardrive.com"
                className="font-bold text-slate-700"
                target="_blank"
              >
                otoweb.betacardrive.com
              </Link>{" "}
              et betacardrive.cm ne peuvent être tenus responsables de la
              modification des dispositions administratives et juridiques
              survenant après la publication. De même, les sites ne peuvent être
              tenus responsables de l’utilisation et de l’interprétation de
              l’information contenue dans ces sites. Le site{" "}
              <Link
                to="https://www.otoweb.betacardrive.com"
                className="font-bold text-slate-700"
                target="_blank"
              >
                otoweb.betacardrive.com
              </Link>{" "}
              ne peut être tenu pour responsable d’éventuels virus qui
              pourraient infecter l’ordinateur ou tout matériel informatique de
              l’Internaute, suite à une utilisation, à l’accès, ou au
              téléchargement provenant de ce site. La responsabilité du site ne
              peut être engagée en cas de force majeure ou du fait imprévisible
              et insurmontable d'un tiers.
            </p>
          </div>
        </div>

        {/** article  6*/}
        <div
          data-aos="fade-up"
          className="aos flex flex-col md:flex-row-reverse items-center py-2 md:pb-8 md:pt-2"
        >
          <img
            src={LinkUrl}
            alt="link illustration"
            className="md:basis-1/3 object-contain h-[15vh] md:h-[40vh] md:translate-y-6 "
          />
          <div className="md:basis-2/3 text-center md:text-start mt-3 md:mt-[10vh]">
            <h2 className="text-2xl text-primary font-bold text-center pb-2">
              ARTICLE 6 : LIENS HYPERTEXTES
            </h2>

            <p className="text-sm md:text-xl text-slate-800 ">
              Des liens hypertextes peuvent être présents sur le site.
              L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira
              du site{" "}
              <Link
                to="https://www.otoweb.betacardrive.com"
                className="font-bold text-slate-700"
                target="_blank"
              >
                otoweb.betacardrive.com
              </Link>
              . Ce dernier n’a pas de contrôle sur les pages web sur lesquelles
              aboutissent ces liens et ne saurait, en aucun cas, être
              responsable de leur contenu.
            </p>
          </div>
        </div>

        {/** article  7*/}
        <div
          data-aos="fade-up"
          className="aos flex flex-col md:flex-row items-center py-2 md:pb-8 md:pt-2"
        >
          <img
            src={Cookie}
            alt="cookies illustration"
            className="md:basis-1/3 object-contain h-[15vh] md:h-[40vh] md:translate-y-6 "
          />
          <div className="md:basis-2/3 text-center md:text-start mt-3 md:mt-[10vh]">
            <h2 className="text-2xl text-primary font-bold text-center pb-2">
              ARTICLE 7 : COOKIES
            </h2>

            <p className="text-sm md:text-xl text-slate-800 ">
              L’Utilisateur est informé que lors de ses visites sur le site, un
              cookie peut s’installer automatiquement sur son logiciel de
              navigation. Les cookies sont de petits fichiers stockés
              temporairement sur le disque dur de l’ordinateur de l’Utilisateur
              par votre navigateur et qui sont nécessaires à l’utilisation du
              site{" "}
              <Link
                to="https://www.otoweb.betacardrive.com"
                className="font-bold text-slate-700"
                target="_blank"
              >
                otoweb.betacardrive.com
              </Link>
              . Les cookies ne contiennent pas d’information personnelle et ne
              peuvent pas être utilisés pour identifier quelqu’un. Un cookie
              contient un identifiant unique, généré aléatoirement et donc
              anonyme. Certains cookies expirent à la fin de la visite de
              l’Utilisateur, d’autres restent. L’information contenue dans les
              cookies est utilisée pour améliorer le site{" "}
              <Link
                to="https://www.otoweb.betacardrive.com"
                className="font-bold text-slate-700"
                target="_blank"
              >
                otoweb.betacardrive.com
              </Link>
              . En naviguant sur le site, L’Utilisateur les accepte.
              L’Utilisateur pourra désactiver ces cookies par l’intermédiaire
              des paramètres figurant au sein de son logiciel de navigation.
            </p>
          </div>
        </div>

        {/** article  8*/}
        <div
          data-aos="fade-up"
          className="aos flex flex-col md:flex-row-reverse items-center py-2 md:pb-8 md:pt-2"
        >
          <img
            src={Court}
            alt="court illustration"
            className="md:basis-1/3 object-contain h-[15vh] md:h-[40vh] md:translate-y-6 "
          />
          <div className="md:basis-2/3 text-center md:text-start mt-3 md:mt-[10vh]">
            <h2 className="text-2xl text-primary font-bold text-center pb-2">
              ARTICLE 8 : DROIT APPLICABLE ET JURIDICTION COMPETENTE
            </h2>

            <p className="text-sm md:text-xl text-slate-800 ">
              La législation camerounaise s'applique au présent contrat. En cas
              d'absence de résolution amiable d'un litige né entre les parties,
              les tribunaux camerounais seront seuls compétents pour en
              connaître l’issue. En cochant la case, vous vous engagez avec nous
              pendant vingt-quatre(24) mois, vous avez compris que votre
              paiement s’étend sur vingt(20) mois et vous bénéficiez de
              quatre(4) mois gratuits.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlesList;
