import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import MaterialReactTable from "material-react-table";
import CircularProgress from "@mui/material/CircularProgress";
import Statistics from "./Statistics";
import { RegisterApi } from "../../utils/ApiRoute";
import { monthList } from "./Constants";

const SubscriptionsList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    const getSusbscriptions = async () => {
      const subscriptionsList = await axios.get(RegisterApi);
      if (subscriptionsList.status === 200) {
        const res = subscriptionsList.data.subscriptions;
        setDataList(res);
        setIsLoading(false);
      }
    };
    getSusbscriptions();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) =>
          `${new Date(row.createdAt).getDate()} ${
            monthList[new Date(row.createdAt).getMonth()]
          } ${new Date(row.createdAt).getFullYear()} à ${new Date(
            row.createdAt
          ).getHours()}h${new Date(row.createdAt).getMinutes()}`, //simple recommended way to define a column
        header: "Souscrit le",
        muiTableHeadCellProps: { sx: { color: "black", fontSize:16 } }, //optional custom props
        Cell: ({ cell }) => <span className="bg-red">{cell.getValue()}</span>, //optional custom cell render
      },
      {
        accessorFn: (row) => row.nom, //simple recommended way to define a column
        header: "Nom(s) et prenom(s)",
        muiTableHeadCellProps: { sx: { color: "black", fontSize:16 } }, //optional custom props
        Cell: ({ cell }) => <span className="bg-red">{cell.getValue()}</span>, //optional custom cell render
      },
      {
        accessorFn: (row) => row.email, //simple recommended way to define a column
        header: "Email",
        muiTableHeadCellProps: { sx: { color: "black", fontSize:16 } }, //optional custom props
        Cell: ({ cell }) => <span className="bg-red">{cell.getValue()}</span>, //optional custom cell render
      },
      {
        accessorFn: (row) => row.tel, //simple recommended way to define a column
        header: "Telephone",
        muiTableHeadCellProps: { sx: { color: "black", fontSize:16 } }, //optional custom props
        Cell: ({ cell }) => <span className="bg-red">{cell.getValue()}</span>, //optional custom cell render
      },
      {
        accessorFn: (row) => row.cni, //simple recommended way to define a column
        header: "Numero de cni",
        muiTableHeadCellProps: { sx: { color: "black", fontSize:16 } }, //optional custom props
        Cell: ({ cell }) => <span className="bg-red">{cell.getValue()}</span>, //optional custom cell render
      },
      {
        accessorFn: (row) => row.social, //simple recommended way to define a column
        header: "Raison sociale",
        muiTableHeadCellProps: { sx: { color: "black", fontSize:16 } }, //optional custom props
        Cell: ({ cell }) => <span className="bg-red">{cell.getValue()}</span>, //optional custom cell render
      },
      {
        accessorFn: (row) => row.siege, //simple recommended way to define a column
        header: "Siege",
        muiTableHeadCellProps: { sx: { color: "black", fontSize:16 } }, //optional custom props
        Cell: ({ cell }) => <span className="bg-red">{cell.getValue()}</span>, //optional custom cell render
      },
      {
        accessorFn: (row) => row.payement, //simple recommended way to define a column
        header: "Mode de paiement",
        muiTableHeadCellProps: { sx: { color: "black", fontSize:16 } }, //optional custom props
        Cell: ({ cell }) => <span className="bg-red">{cell.getValue()}</span>, //optional custom cell render
      },
      {
        accessorFn: (row) => row.registre, //simple recommended way to define a column
        header: "Registre de commerce",
        muiTableHeadCellProps: { sx: { color: "black", fontSize:16 } }, //optional custom props
        Cell: ({ cell }) => <span className="bg-red">{cell.getValue()}</span>, //optional custom cell render
      },
      {
        accessorFn: (row) => row.attestation, //simple recommended way to define a column
        header: "Attestation d'immatriculation",
        muiTableHeadCellProps: { sx: { color: "black", fontSize:16 } }, //optional custom props
        Cell: ({ cell }) => <span className="bg-red">{cell.getValue()}</span>, //optional custom cell render
      },

      {
        accessorFn: (row) => row.lien, //simple recommended way to define a column
        header: "Lien du site à créer",
        muiTableHeadCellProps: { sx: { color: "black", fontSize:16 } }, //optional custom props
        Cell: ({ cell }) => <span className="bg-red">{cell.getValue()}</span>, //optional custom cell render
      },

      {
        accessorFn: (row) => row.souhait, //simple recommended way to define a column
        header: "Type de site voulu",
        muiTableHeadCellProps: { sx: { color: "black", fontSize:16 } }, //optional custom props
        Cell: ({ cell }) => <span className="bg-red">{cell.getValue()}</span>, //optional custom cell render
      },
      /* {
        accessorFn: (row) => row.age, //alternate way
        id: 'age', //id required if you use accessorFn instead of accessorKey
        header: 'Age',
        Header: () => <p>Ages</p>, //optional custom header render
      }, */
    ],
    []
  );

  if (!isLoading) {
    return (
      <section>
        <Statistics data={dataList} />
        <div>
          <p className="mb-2 mt-12 text-center text-primary font-bold text-lg md:text-2xl">
            TABLEAU DES SOUSCRIPTIONS
          </p>
          <MaterialReactTable
            columns={columns}
            data={dataList}
            enableColumnOrdering //enable some features
            enablePagination={true} //disable a default feature
          />
        </div>
      </section>
    );
  } else {
    return (
      <div className="h-[40vh] flex justify-center items-center py-10">
        <CircularProgress size="60px" />
      </div>
    );
  }
};

export default SubscriptionsList;
