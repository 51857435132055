import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { InputAdornment } from "@mui/material";
import { AiFillMail, AiFillLock, AiFillUnlock } from "react-icons/ai";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AdminApi } from "../../utils/ApiRoute";
import logo from "../../assets/logo1.png";

const LoginComponent = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [loginParams, setLoginParams] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const admin = localStorage.getItem("admin");
    if(admin){navigate("/management")}
  },[navigate])

  const handleRedirect = () => {
    navigate("/");
  };

  const handleLogin = async () => {
    setIsLoading(true);
    if (loginParams.email && loginParams.password) {
      const res = await axios.post(AdminApi, loginParams);
      if (res.data.status) {
        localStorage.setItem("admin", res.data.name);
        console.log(localStorage.getItem("admin"))
        toast.success("connexion réussie");
        navigate("/management");
      } else {
        toast.error("utilisateur non trouvé, veuillez réessayer");
      }
      setIsLoading(false);
    } else {
      toast.error("veuillez entrer des valeurs correctes");
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    setLoginParams({ ...loginParams, [name]: value });
  };

  const handleShow = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <section className="w-full  h-[100vh] flex justify-center items-center bg-gradient-to-r from-blue-300 to-blue-400 p-6 md:p-10 box-border">
      <div className="bg-white w-full md:w-[400px] shrink-0 grow-0  rounded-xl shadow-xl p-4 md:p-8">
        <div className="flex justify-center items-center">
          <img
            src={logo}
            alt="logo"
            className="hover:cursor-pointer h-14 translate-x-[-5px]"
            onClick={handleRedirect}
          />
        </div>
        <div className="my-3">
          <p className="text-slate-600 text-center font-bold text-lg ">
            Connexion administrateur
          </p>

          <div className="">
            <TextField
              sx={{ width: "100%", marginY: 3 }}
              id="outlined-basic"
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              size="small"
              value={loginParams.email}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiFillMail />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              sx={{ width: "100%" }}
              id="outlined-basic"
              name="password"
              label="Mot de passe"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              size="small"
              value={loginParams.password}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiFillLock
                      className={` ${
                        showPassword ? "hidden" : "inline-block"
                      } hover:cursor-default`}
                      onClick={handleShow}
                    />
                    <AiFillUnlock
                      className={` ${
                        !showPassword
                          ? "hidden"
                          : "inline-block   hover:cursor-default"
                      }`}
                      onClick={handleShow}
                    />
                  </InputAdornment>
                ),
              }}
            />

            <button
              disabled={isLoading ? true : false}
              className="flex justify-center items-center bg-primary py-2 w-full text-white my-4 rounded-md hover:bg-primary/80 hover:cursor-pointer "
              onClick={handleLogin}
            >
              {isLoading && <CircularProgress size="24px" color="inherit" />}
              {!isLoading && "connexion"}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default LoginComponent;
