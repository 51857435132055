import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineHome, AiOutlinePoweroff } from "react-icons/ai";
import { MdOutlineDashboard } from "react-icons/md";
import logo from "../../assets/logo1.png";

const Navbar = () => {
  //const [open , setOpen ] = useState(false);
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/");
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/connexion");
  }

  return (
    <nav className="bg-white z-40 px-6 md:px-20 py-2  flex overflow-hidden sticky top-0 shadow-lg ">
      <div className="basis-1/3">
        <img
          src={logo}
          alt="logo"
          className=" grow-0 shrink-0 object-contain pt-1 h-8 md:h-10 hover:cursor-pointer "
          onClick={handleNavigation}
        />
      </div>
      <div className="basis-1/3 flex items-center justify-center text-cyan-900">
        <MdOutlineDashboard className="mr-1  text-lg" />
        <p className=" text-md md:text-xl font-bold  ">DASHBOARD</p>
      </div>
      <div className="basis-1/3 [&>*]:ml-2 md:[&>*]:ml-6 [&>*]:text-slate-600 hover:[&>*]:text-primary hover:[&>*]:cursor-pointer flex items-center justify-end">
        <div className="flex items-center " onClick={() => navigate("/")}>
          <AiOutlineHome className="mr-1" />
          <p className="hidden md:inline-block" >Accueil</p>
        </div>
        <div className="flex items-center" onClick = {handleLogout}>
          <AiOutlinePoweroff className="mr-1" />
          <p className="hidden md:inline-block">Deconnexion</p>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
