import React from "react";
import { FaUsers, FaUserPlus } from "react-icons/fa";
import { newMonthlyUsers, paymentStats, websiteTypeStats } from "./Constants";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const Statistics = ({ data }) => {
  const doughnutPaymentData = {
    labels: ["Orange Money", "MOMO", "Yellow", "Carte Bancaire", "autre"],
    datasets: [
      {
        label: "choix paiement",
        data: paymentStats(data),
        backgroundColor: [
          "#f97316",
          "#fde047",
          "#eab308",
          "#22d3ee",
          "#d946ef",
        ],
        borderColor:[
          "#f97316",
          "#fde047",
          "#eab308",
          "#22d3ee",
          "#d946ef",
        ],
        borderWidth: 1,
      },
    ],
  };

  const doughnutWebsiteData = {
    labels: ['Site Vitrine', 'E-commerce',  'autre'],
    datasets: [
      {
        label: "Type site client: ",
        data: websiteTypeStats(data),
        backgroundColor: [
          "#4ade80",
          "#a855f7",
          "#f43f5e",
        ],
        borderColor: [
          "#4ade80",
          "#a855f7",
          "#f43f5e",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="my-4">
      <div className="flex items-center grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 [&>*]:p-2 [&>*]:rounded-md">
        <div className=" bg-green-500 text-white lg:h-[18vh] ">
          <p className="font-bold uppercase text-xl">Total souscriptions </p>
          <div className="flex justify-between items-center mt-2">
            <FaUsers className="h-[40px] w-[40px]" />
            <p className="text-[35px] font-bold mr-2">{data.length}</p>
          </div>
        </div>

        <div className=" bg-orange-400 text-white lg:h-[18vh]  ">
          <p className="font-bold uppercase text-xl">Souscriptions du mois</p>
          <div className="flex justify-between items-center mt-2">
            <FaUserPlus className="h-[40px] w-[40px]" />
            <p className="text-[35px] font-bold mr-2">
              {newMonthlyUsers(data)}
            </p>
          </div>
        </div>
        <div className={`${data.length === 0 ? "hidden":"inline-block relative"}`}>
          <Doughnut
            data={doughnutPaymentData}
            options={{ maintainAspectRatio: false,plugins:{legend:{display:false}} }}
          />
          <p className="absolute bottom-[-3] font-bold text-lg text-slate-600 text-center w-full ">Statistique de paiements</p>
        </div>

        <div className={`${data.length === 0 ? "hidden":"inline-block relative"}`}>
          <Doughnut
            data={doughnutWebsiteData}
            options={{ maintainAspectRatio: false, plugins:{legend:{display:false}} }}
          />
           <p className="absolute bottom-[-3] font-bold text-lg text-slate-600 text-center w-full ">Statistique des types de site</p>
        </div>
      </div>
    </div>
  );
};

export default Statistics;

/**
 *  <div className=" bg-red-400 h-[18vh]"></div>
        <div className=" bg-yellow-400 h-[18vh]"></div>
        <div className=" bg-orange-400 h-[18vh]"></div>
 */
