import React, { useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

const Description = () => {
  const [id, setId] = useState({first: false , second: false , third:false , fourth:false, fifth:false});
  return (
    <div className="md:pt-4 md:pb-8">
      {/**informations une  */}
      <div>
        <div
          className={`flex justify-between items-center text-primary  border-b-2  py-2 mr-2 ${
            id.first? "border-primary" : "border-slate-400 "
          } `}
        >
          <p className=" font-bold text-lg md:text-xl">
            Constitution du programme 100 entreprises 100 sites web
          </p>
          <IoIosArrowDown
            onClick={() => setId({...id, first:true})}
            className={`text-2xl translate-y-1 hover:cursor-pointer  ${
              !id.first ? "inline" : "hidden"
            }  `}
          />
          <IoIosArrowUp
            onClick={() => setId({...id, first:false})}
            className={`text-2xl translate-y-1 hover:cursor-pointer ${
              id.first ? "inline" : "hidden"
            }  `}
          />
        </div>
        <div
          className={`ml-2 md:ml-6 ${id.first ? "inline-block " : "hidden"}`}
        >
          <p className="my-2 text-slate-800 font-bold text-md md:text-lg">
            Le programme 100 entreprises 100 sites web comprend :
          </p>
          <div className="ml-4 text-md md:text-lg text-slate-800 py-2 ">
            <p>- Conception ou refonte de site internet</p>
            <p>- Conception d’application web et mobile</p>
            <p>- Création et configuration des pages sur les réseaux sociaux</p>
            <p>- Hébergement offert pendant deux ans</p>
            <p>- Nom de domaine offert pendant deux ans</p>
            <p>- Mise à jour du certificat SSL</p>
            <p>
              - Inscription et configuration de votre site web sur Google,
              Google Maps, Google My Business
            </p>
            <p>
              - Rendre disponible l’accès des informations de l’entreprise sur
              Google (contact, lien du site web, jours et horaires d’ouverture,
              adresse mail, liens vers les différents réseaux sociaux)
            </p>
            <p>
              - Système de notation basé sur les avis clients et/ou visiteurs
            </p>
            <p>- Référencement (SEO)</p>
            <p>
              - Configuration des pages (FACEBOOK, TWEETER, LINKEDIN, INSTAGRAM)
            </p>
            <p>
              - Boost d’une page au choix une fois tous les trois mois pendant
              deux semaines durant tout le contrat.
            </p>
            <p>
              - Formation à l’utilisation du back office pour les sites
              e-commerce et de gestion
            </p>
            <p>- Maintenance et conseil</p>
            <p>
              - Support technique de lundi à vendredi de 9H à 18H pendant
              vingt-quatre (24) mois.
            </p>
          </div>
        </div>
      </div>

      {/**information deux */}
      <div className="mt-4">
        <div
          className={`flex justify-between items-center text-primary  border-b-2  py-2 mr-2 ${
            id.second? "border-primary" : "border-slate-400 "
          } `}
        >
          <p className=" font-bold text-lg md:text-xl">
            Informations concernant l'accès au programme
          </p>
          <IoIosArrowDown
            onClick={() => setId({...id, second:true})}
            className={`text-2xl translate-y-1 hover:cursor-pointer ${
              !id.second ? "inline" : "hidden"
            }  `}
          />
          <IoIosArrowUp
            onClick={() => setId({...id, second:false})}
            className={`text-2xl translate-y-1 hover:cursor-pointer ${
              id.second ? "inline" : "hidden"
            }  `}
          />
        </div>
        <div className={`ml-2 md:ml-6 ${id.second ? "inline-block" : "hidden"}`}>
          <p className="my-2 text-slate-800  text-md md:text-lg">
            Le programme ne nécessite pas de frais d’adhésion et est accessible
            en tout lieu à tout utilisateur ayant un accès à Internet. Les frais
            supportés par l'Utilisateur pour accéder au service (matériel
            informatique, logiciels, connexion Internet, etc.) sont à sa charge
            et ne nous sont pas destinés.
          </p>
        </div>
      </div>

      {/**information trois */}
      <div className="mt-4">
        <div
          className={`flex justify-between items-center text-primary  border-b-2  py-2 mr-2 ${
            id.third ? "border-primary" : "border-slate-400 "
          } `}
        >
          <p className=" font-bold text-lg md:text-xl">
            Informations sur la durée de paiement
          </p>
          <IoIosArrowDown
            onClick={() => setId({...id, third:true})}
            className={`text-2xl translate-y-1 hover:cursor-pointer ${
              !id.third? "inline" : "hidden"
            }  `}
          />
          <IoIosArrowUp
            onClick={() => setId({...id, third:false})}
            className={`text-2xl translate-y-1 hover:cursor-pointer ${
              id.third ? "inline" : "hidden"
            }  `}
          />
        </div>
        <div className={`ml-2 md:ml-6 ${id.third ? "inline-block" : "hidden"}`}>
          <p className="my-2 text-slate-800  text-md md:text-lg">
            En souscrivant au service, l’entreprise s’engage pour une durée de
            vingt-quatre (24) mois, pour un montant mensuel applicable pendant
            vingt (20) mois de trente (30 000) mille CFA avec quatre(4) mois
            offerts. Le paiement se fera à la convenance du client (virement,
            MOMO ou OrangeMoney). En cas de paiement par virement bancaire, le
            client devra fournir un relevé bancaire lors du remplissage du
            formulaire.
          </p>
        </div>
      </div>

      {/**informations quatre  */}
      <div className="mt-4">
        <div
          className={`flex justify-between items-center text-primary  border-b-2  py-2 mr-2 ${
            id.fourth ? "border-primary" : "border-slate-400 "
          } `}
        >
          <p className=" font-bold text-lg md:text-xl">
            Information concernant l'assurance
          </p>
          <IoIosArrowDown
            onClick={() => setId({...id, fourth:true})}
            className={`text-2xl translate-y-1 hover:cursor-pointer  ${
             !id.fourth ? "inline" : "hidden"
            }  `}
          />
          <IoIosArrowUp
            onClick={() => setId({...id, fourth:false})}
            className={`text-2xl translate-y-1 hover:cursor-pointer ${
              id.fourth ? "inline" : "hidden"
            }  `}
          />
        </div>
        <div
          className={`ml-2 md:ml-6  ${id.fourth ? "inline-block" : "hidden"}`}
        >
          <div className="ml-4 text-md md:text-lg text-slate-800 py-2 [&>*]:mb-2">
            <div>
              1. Le site internet est conçu totalement et hébergé avant le début
              des prélèvements automatiques. Le cahier de charge est conçu
              obligatoirement par Bëtacar Drive Tech. et vaut QUINZE-MILLE (15 000)
              XAF CFA en paiement unique à l’avance au début de la rédaction du
              cahier de charge.{" "}
            </div>
            <div>
              2. Les comptes d’hébergement des applications sont entièrement à
              la charge du client, ce dernier est guidé pour la procédure de
              création de ses comptes pour préserver la confidentialité liée à
              ses mots de passe et autres informations sensibles lors de la
              création.{" "}
            </div>
            <div>
              3. Le présent contrat prend fin à la date d’anniversaire de la
              durée du programme, le client a le choix de reconduire ou pas et
              c’est sans frais.
            </div>
          </div>
        </div>
      </div>

      {/**informations cinq  */}
      <div className="mt-4">
        <div
          className={`flex justify-between items-center text-primary  border-b-2  py-2 mr-2 ${
            id.fifth ? "border-primary" : "border-slate-400 "
          } `}
        >
          <p className=" font-bold text-lg md:text-xl">
            Information concernant l'abus
          </p>
          <IoIosArrowDown
            onClick={() => setId({...id, fifth:true})}
            className={`text-2xl translate-y-1 hover:cursor-pointer  ${
              !id.fifth ? "inline" : "hidden"
            }  `}
          />
          <IoIosArrowUp
            onClick={() => setId({...id, fifth:false})}
            className={`text-2xl translate-y-1 hover:cursor-pointer ${
              id.fifth ? "inline" : "hidden"
            }  `}
          />
        </div>
        <div
          className={`ml-2 md:ml-6  ${id.fifth ? "inline-block" : "hidden"}`}
        >
          <div className="ml-4 text-md md:text-lg text-slate-800 py-2 [&>*]:mb-2">
            <div>
              1. En cas d’annulation du contrat pendant son cours, le client
              paye le nombre de mois restants si les services ont été entamés
              (sauf désistement de Bëtacar Drive) ou ont été rendus disponibles
              par les équipes d’OTOWEB au moment de l’annulation par le client.
            </div>
            <div>
              2. En cas de non-respect de la clause de paiement pour une raison
              ou une autre (Blocage des prélèvements, compte non approvisionné,
              le client retire OTOWEB de l’administration ou le contrôle d’une
              ou plusieurs plateformes (FACEBOOK, INSTAGRAM, TWEETER, LINKEDIN)
              etc.), les équipes de Bëtacar Drive Tech. peuvent:
              <div className="ml-2">
                <p> - Bloquer le site s’il est déjà conçu </p>
                <p>
                  {" "}
                  - Intenter une action en justice si la conception a été
                  entamée et que l’annulation est sans motif valable, si aucun
                  accord à l’amiable n’est trouvé.{" "}
                </p>
                <p>
                  {" "}
                  - Les sites et plateformes conçus peuvent devenir des
                  propriétés de Bëtacar Drive ou Global Crea et utilisé(e)s à
                  leurs guises si le client ne paye pas au bout de trois mois.{" "}
                </p>
                <p>
                  - Demander des frais d’indemnités de cinq mille (5000) XAF
                  CFA par jour après sept (7) jour en cas de retard de paiement,
                  ce avec un délai de trente (30) jours.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
