import React from "react";
import { Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import ManagementPage from "./pages/ManagementPage";
import SubscriptionPage from "./pages/SubscriptionPage";


const Router = () => {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<LandingPage />} />

        <Route path="connexion" element={<LoginPage />} />
        <Route path="management" element={<ManagementPage />} />
        <Route path="souscription" element={<SubscriptionPage />} />
      </Route>
    </Routes>
  );
};

export default Router;
