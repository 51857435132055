import React from "react";
import heroImage from "../../assets/heroImage1.png";

const HeroSection = () => {
  return (
    <div className="md:h-[70vh] flex md:flex-row flex-col ">
      <div className="md:basis-2/5 py-4 flex flex-col justify-center items-center md:items-start">
        <h1 className="text-primary text-3xl md:text-4xl font-bold my-4 text-center md:text-start">
          TERMES &<br /> CONDITIONS
        </h1>
        <div className="text-lg md:text-xl text-center md:text-start text-slate-700">
          <p className="mb-2 ">
            Conditions générales d'utilisation du programme 100 SITES WEB. En
            vigueur au jour de l’acceptation par vous.
          </p>
          <p>
            VEUILLEZ VRAIMENT LIRE ATTENTIVEMENT AVANT DE SIGNER S’IL VOUS PLAIT
          </p>
        </div>
      </div>
      <div className="h-[40vh] md:h-auto md:basis-3/5  overflow-hidden">
        <img
          src={heroImage}
          alt="hero"
          className=" h-full w-full scale-[1.6] md:scale-[1] object-contain md:object-cover "
        />
      </div>
    </div>
  );
};

export default HeroSection;
