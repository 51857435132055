import React,{useEffect, useState} from "react";
import { useNavigate } from "react-router";
import Navbar from "../components/Management/Navbar";
import SubscriptionsList from "../components/Management/SubscriptionsList";
import SEO from "../components/SEO";

const ManagementPage = () => {
  const [adminName, setAdminName ] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const admin = localStorage.getItem("admin");
    if(!admin){navigate("/login")}
    else {
      setAdminName(admin);
    }
  },[navigate])
  return (
   <>
   <SEO  title="Bëtacar - Dashboard" description="Dashboard admin du site bëtacar"/>
    <section className="box-border">
      <Navbar />
      <div className=" px-6 md:px-20 bg-[#F9FAFE] py-4 md:py-4 break-words">
        <p className="text-center text-lg md:text-start md:text-xl font-bold py-2 text-slate-700 ">
          Bienvenu <span className="text-primary">{adminName}</span>{" "}
        </p>
        <SubscriptionsList/>
      </div>
    </section></>
  );
};

export default ManagementPage;
