import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/logo1.png";

const Navbar = () => {
    //const [open , setOpen ] = useState(false);
    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate("/")
    }

  return (
    <nav className ="bg-white z-40 px-10 md:px-20  flex justify-center py-2 items-center overflow-hidden sticky top-0 shadow-lg ">
       <img src={logo} alt="logo" className=" grow-0 shrink-0 object-contain pt-1 h-10 hover:cursor-pointer " onClick={handleNavigation} />    
    </nav>
  )
}

export default Navbar