import React from "react";
import { Link } from "react-router-dom";
import { GrMail } from "react-icons/gr";
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
import { BsTelegram } from "react-icons/bs";
import { BsTelephoneFill } from "react-icons/bs";
import FooterLogo from "../../assets/logo1.png";

const Footer = () => {
  return (
    <div className="shadow-lg md:px-10 py-2 bg-primary text-white  ">
      <div className="flex flex-col md:flex-row items-center md:items-start md:justify-around w-full h-full py-2 ">
        <div className=" flex ">
          <img
            src={FooterLogo}
            alt="footer logo"
            className=" grow-0 shrink-0 object-contain pt-1 h-12 hover:cursor-pointer "
          />
        </div>
        <div className=" text-center my-4 md:my-0 ">
          <p className="text-lg font-bold ">Contactez nous</p>
          <div className="flex flex-col md:flex-row items-center md:items-start justify-start md:justify-center [&>*]:mx-4 pt-1">
            <p className="flex  items-center">
              <BsTelephoneFill className="text-xl" />
              <span className="ml-1 text-md">+237 699 23 44 47</span>
            </p>
            <p className="flex items-center">
              <GrMail className="text-xl" />
              <span className="ml-1 text-md"> otoweb@betacardrive.com</span>
            </p>
          </div>
        </div>
        <div className=" text-center pt-2">
          <p className="text-lg font-bold"> Suivez nous</p>
          <div className="flex justify-center pt-1 [&>*]:mx-4 [&>*]:text-xl [&>*]:cursor-pointer">
            <Link
              to="https://www.facebook.com/betacardrive?mibextid=LQQJ4d"
              target="_blank"
              className="flex items-center"
            >
              <AiFillFacebook />
            </Link>
           
            <Link
              to="https://www.linkedin.com/in/frankee-seme-9a65109b"
              target="_blank"
              className="flex items-center"
            >
              <AiFillLinkedin />
            </Link>
            <Link
              to="https://t.me/+UR8bEHHfFTNwNK8A"
              target="_blank"
              className="flex items-center"
            >
              <BsTelegram />
            </Link>
          </div>
        </div>
      </div>
      <div className="text-center font-bold text-md border-t border-white mt-3 py-1">
        © sous protection conçu avec amour par Bëtacar Drive 2023
      </div>
    </div>
  );
};
export default Footer;
