import React from "react";
import "react-toastify/dist/ReactToastify.css"
import Router from "./Router";

const App = () => {
  return (
    <Router/>
  );
};

export default App;
