import React from "react";
import LoginComponent from "../components/LoginPage/LoginComponent";
import SEO from "../components/SEO";

const LoginPage = () => {
  return (
    <>
     <SEO  title="Connexion" description="connexion administrateur"/>
      <LoginComponent />
    </>
  );
};

export default LoginPage;
