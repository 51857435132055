import React from "react";
import Subscription from "../components/Subscription/SubscriptionForm";
import Navbar from "../components/LandingPage/Navbar";
import Footer from "../components/LandingPage/Footer";
import SEO from "../components/SEO";

const SubscriptionPage = () => {
  return (
   <>
    <SEO  title="Bëtacar - Souscription" description="Page de souscription du site bëtacar"/>
    <section>
      <Navbar />
      <div className="px-6 md:px-0 md:mx-20  mx-auto min-h-[80vh]">
        <Subscription />
      </div>
      <Footer />
    </section></>
  );
};

export default SubscriptionPage;
