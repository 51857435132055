import React, { useState } from "react";
import { useNavigate } from "react-router";
import Modal from "@mui/material/Modal";
import Navbar from "../components/LandingPage/Navbar";
import HeroSection from "../components/LandingPage/HeroSection";
import ArticlesList from "../components/LandingPage/ArticlesList";
import Description from "../components/LandingPage/Description";
import Footer from "../components/LandingPage/Footer";
import SEO from "../components/SEO";
import Logo from "../assets/logo1.png";

const LandingPage = () => {
  const [checked, setChecked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setOpenModal(false);

  const handleCheck = () => {
    setOpenModal(true);
    setChecked(true);
  };
  return (
    <>
     <SEO  title="Bëtacar - Accueil" description="Page d'accueil du site bëtacar"/>
      <section className="">
        <Navbar />
        <section className="px-6 md:px-0 md:mx-20  mx-auto">
          <HeroSection />
          <ArticlesList />
          <Description />
          <div className="mb-8 mt-6 flex justify-center text-center">
            <input
              type="checkbox"
              checked={checked}
              className="w-8 md:w-6"
              onChange={handleCheck}
              name="accept"
            />
            <label
              for="accept"
              className="ml-2 font-bold text-xl md:text-2xl text-primary"
            >
              J'AI LU ET J'ACCEPTE LES CONDITIONS
            </label>
          </div>
        </section>
        <Footer />
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="bg-transparent h-full w-full flex justify-center items-center">
            <div className="bg-white p-4 min-h-[35vh] min-w-[40vw] shadow-xl rounded-lg m-4 ">
              <div className="flex justify-center items-center">
                <img
                  src={Logo}
                  className="grow-0 shrink-0  h-12 translate-x-[-2]"
                  alt="Bëtacar"
                />
              </div>
              <div className="my-8 px-3 text-center ">
                <p className="text-xl text-slate-600 font-bold">
                  Nous vous remercions d'avoir lu et accepté les conditions
                  d'utilisations de notre programme{" "}
                </p>
              </div>
              <div className="flex justify-center [&>*]:mr-4 [&>*]:py-1 [&>*]:px-6 [&>*]:text-md  [&>*]:rounded-sm  [&>*]:border ">
                <button
                  className="border-primary text-primary hover:bg-slate-100"
                  onClick={() => {
                    handleClose();
                    setChecked(false);
                  }}
                >
                  Annuler
                </button>
                <button
                  className="bg-primary text-white border-primary hover:opacity-[0.9]"
                  onClick={() => {
                    navigate("/souscription");
                  }}
                >
                  Souscrire
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </section>
    </>
  );
};

export default LandingPage;
